import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

import logoBrainWhite from "../img/brain-white.svg";

class TagRoute extends React.Component {
  formatSlug(str) {
    let regex = /\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])*/gm;

    if (regex.test(str)) {
      // Remove YYYY-MM-DD from blog slug
      str = str
        .split("/blog/")[1]
        .replace(regex, "")
        .substr(1);
      // Rebuild slug string
      str = str.replace(/^-/, "");
      str = "/blog/" + str;
    }

    return str;
  }

  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const tag = this.props.pageContext.tag;
    const title = tag + " | Amos Institute";

    var description = null;
    if (tag === "Recipes") {
      description =
        "Bredesen Protocol approved recipes curated by our registered dietitian nutritionists.";
    } else if (tag === "Nutrition") {
      description =
        "Stay current with the latest research for optimizing and personalizing your nutrition plan.";
    } else if (tag === "Lifestyle") {
      description =
        "Exercise, sleep, stress management techniques and more to improve your brain and overall health.";
    } else if (tag === "News") {
      description =
        "Breaking headlines and noteworthy information regarding cognitive health and the Bredesen Protocol.";
    }

    return (
      <Layout>
        <Helmet title={title}>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className="blog">
          <div
            className="page-header full-width-image-container"
            data-tag={tag}
            style={{
              backgroundImage: "none",
              padding: "0 15px",
            }}
          >
            <h1 className="has-text-white has-text-weight-bold is-size-1">
              <img
                className="header-img inline"
                src={logoBrainWhite}
                alt="Amos Institute Logo"
              />
              {tag}
            </h1>
            {tag !== "Recipes" ? null : (
              <p className="has-text-white has-text-centered subtitle">
                {description}
              </p>
            )}

            {tag !== "Nutrition" ? null : (
              <p className="has-text-white has-text-centered subtitle">
                {description}
              </p>
            )}

            {tag !== "Lifestyle" ? null : (
              <p className="has-text-white has-text-centered subtitle">
                {description}
              </p>
            )}

            {tag !== "News" ? null : (
              <p className="has-text-white has-text-centered subtitle">
                {description}
              </p>
            )}
          </div>
          <section
            className="blog-category-menu section"
            style={{
              marginBottom: "60px",
            }}
          >
            <div className="container">
              <div className="columns">
                {tag === "Recipes" ? null : (
                  <Link className="column is-one-fifth" to="/blog/recipes/">
                    <div
                      className="category-title is-size-5"
                      data-tag="Recipes"
                    >
                      Recipes
                    </div>
                    <small className="has-text-grey-dark">
                      Bredesen Protocol approved recipes curated by our
                      registered dietitian nutritionists.
                    </small>
                  </Link>
                )}
                {tag === "Nutrition" ? null : (
                  <Link className="column is-one-fifth" to="/blog/nutrition/">
                    <div
                      className="category-title is-size-5"
                      data-tag="Nutrition"
                    >
                      Nutrition
                    </div>
                    <small className="has-text-grey-dark">
                      Stay current with the latest research for optimizing and
                      personalizing your nutrition plan.
                    </small>
                  </Link>
                )}
                {tag === "Lifestyle" ? null : (
                  <Link className="column is-one-fifth" to="/blog/lifestyle/">
                    <div
                      className="category-title is-size-5"
                      data-tag="Lifestyle"
                    >
                      Lifestyle
                    </div>
                    <small className="has-text-grey-dark">
                      Exercise, sleep, stress management techniques and more to
                      improve your brain and overall health.
                    </small>
                  </Link>
                )}
                {tag === "News" ? null : (
                  <Link className="column is-one-fifth" to="/blog/news/">
                    <div className="category-title is-size-5" data-tag="News">
                      News
                    </div>
                    <small className="has-text-grey-dark">
                      Breaking headlines and noteworthy information regarding
                      cognitive health and the Bredesen Protocol.
                    </small>
                  </Link>
                )}
              </div>
            </div>
          </section>
          <div
            className="container content"
            style={{ padding: "0 15px", marginBottom: "25px" }}
          >
            <div className="columns is-multiline">
              {posts &&
                posts.map(({ node: post }, i) => (
                  <div className="column is-4" key={post.fields.slug + i}>
                    <Link
                      className="card"
                      to={this.formatSlug(post.fields.slug)}
                    >
                      <div className="card-image">
                        <img
                          src={
                            post.frontmatter.featuredimage.childImageSharp.fluid
                              .src
                          }
                          alt={post.frontmatter.featuredimageAltTxt}
                        />
                      </div>
                      <div className="card-content">
                        <small
                          className="card-category-tag"
                          data-tag={post.frontmatter.tags}
                        >
                          {post.frontmatter.tags}
                        </small>
                        <div className="card-title has-text-weight-semibold is-size-4">
                          {post.frontmatter.title}
                        </div>
                        <div className="card-desc">
                          {post.frontmatter.description}
                        </div>
                        <div className="card-footer">
                          <span className="has-text-grey">
                            {post.frontmatter.date}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            description
            featuredimageAltTxt
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
